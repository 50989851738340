import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { CallState, LoadingState } from '@core/meta-reducers/call-state';
import { AboutResponse } from '@modules/about/response-about';
import { actionSelectComposition } from '@modules/composition-list/store/composition-list.actions';
import * as panelActions from '@modules/map-panel/store/map-panel.actions';
import { actionOpenTableFromProfile } from '@modules/profile';

import * as apiActions from './api.actions';
import * as APIModels from './models';
import { Fieldset } from './models';
import * as objectActions from './object.actions';
import * as tableActions from './table.actions';

export interface ApiState {
  callState: CallState;
  composition_name?: string;
  models?: ModelsState;
  settings?: APIModels.AttributesSettings;
  mapSettings?: APIModels.MapSettings;
  about?: AboutResponse;
  groups?: APIModels.GroupsResponse[];
  apps?: APIModels.AppsResponse[];
  attachmentFieldsets?: Fieldset[];
  issueFieldsets?: Fieldset[];
}

export interface ModelsState extends EntityState<APIModels.ModelNormalize> {
  callState: CallState;
}

export interface RowsState extends EntityState<APIModels.ObjectResponse> {
  callState: CallState;
}
export const rowAdapter: EntityAdapter<APIModels.ObjectResponse> =
  createEntityAdapter<APIModels.ObjectResponse>({
    selectId: (rowResult: APIModels.ObjectResponse) => rowResult.id,
    sortComparer: false,
  });

export const modelAdapter: EntityAdapter<APIModels.ModelNormalize> =
  createEntityAdapter<APIModels.ModelNormalize>({
    selectId: (modelResult: APIModels.ModelNormalize) =>
      modelResult.django_content_type,
    sortComparer: false,
  });

export const initialState: ApiState = {
  callState: LoadingState.INIT,
  models: modelAdapter.getInitialState({
    callState: LoadingState.INIT,
  }),
};

/** handle transitions from one state to the next state */
const reducer = createReducer(
  initialState,
  on(
    apiActions.actionLoadInitialSettings,
    apiActions.actionAboutGet,
    objectActions.actionObjectGet,
    (state): ApiState => ({
      ...state,
      callState: LoadingState.LOADING,
    }),
  ),
  on(apiActions.actionInstanceSettingsGetSuccess, (state, { payload }) => ({
    ...state,
    apps: payload.structure.apps,
    callState: LoadingState.LOADED,
    groups: payload.structure.groups,
    mapSettings: payload.map,
    models: modelAdapter.setAll(payload.structure.models, {
      ...state.models,
      callState: LoadingState.LOADED,
    }),
    settings: payload.attributes,
  })),
  on(
    apiActions.actionAboutGetSuccess,
    (state, { payload }): ApiState => ({
      ...state,
      about: payload,
      callState: LoadingState.LOADED,
    }),
  ),
  on(
    apiActions.actionSetMapComposition,
    actionSelectComposition,
    (state, { compositionName }): ApiState => ({
      ...state,
      composition_name: compositionName,
    }),
  ),
  on(
    apiActions.actionAutorizationModalClose,
    (state): ApiState => ({
      ...state,
      composition_name: '',
      settings: null,
    }),
  ),
  on(
    apiActions.actionLoadInitialSettings,
    (state): ApiState => ({
      ...state,
      models: {
        ...state.models,
        callState: LoadingState.LOADING,
      },
    }),
  ),
  on(
    panelActions.actionTableGet,
    tableActions.actionTableGet,
    actionOpenTableFromProfile,
    tableActions.actionTableOrdering,
    tableActions.actionTableFiltering,
    tableActions.actionTableSearching,
    tableActions.actionTableGetGeometries,
    tableActions.actionTableLoadMoreData,
    (state, { contentType }) => ({
      ...state,
      models: modelAdapter.mapOne(
        {
          id: contentType,
          map: () => ({
            callState: LoadingState.LOADING,
          }),
        },
        { ...state.models },
      ),
    }),
  ),
  on(
    tableActions.actionTableGetOptionsSuccess,
    objectActions.actionObjectGetOptionsSuccess,
    (state, { model }) => ({
      ...state,
      models: modelAdapter.upsertOne(model, { ...state.models }),
    }),
  ),
  on(
    tableActions.actionTableGetDataSuccess,
    (state, { contentType, rows, filterCount, objectCount }) => ({
      ...state,
      models: modelAdapter.mapOne(
        {
          id: contentType,
          map: (entity) => ({
            callState: LoadingState.LOADED,
            object_count: objectCount || entity.object_count,
            object_count_filter: filterCount,
            rows: rowAdapter.setAll(rows, { ...entity.rows }),
          }),
        },
        { ...state.models },
      ),
    }),
  ),
  on(
    tableActions.actionInlineTableGetDataSuccess,
    (state, { contentType, rows, objectCount }) => ({
      ...state,
      models: modelAdapter.mapOne(
        {
          id: contentType,
          map: (entity) => ({
            callState: LoadingState.LOADED,
            inlineRows: rowAdapter.setAll(rows, { ...entity.rows }),
            inline_object_count: objectCount || entity.object_count,
          }),
        },
        { ...state.models },
      ),
    }),
  ),
  on(
    tableActions.actionTableGetGeometriesSuccess,
    (state, { contentType, rows }) => ({
      ...state,
      models: modelAdapter.mapOne(
        {
          id: contentType,
          map: (entity) => ({
            rows: rowAdapter.upsertMany([...rows], { ...entity.rows }),
          }),
        },
        { ...state.models },
      ),
    }),
  ),
  on(
    panelActions.actionUpdateFilter,
    (state, { modelId, columnName, filter, value }) => ({
      ...state,
      models: modelAdapter.mapOne(
        {
          id: modelId,
          map: (entity) => ({
            columns: entity.columns.map((column) => {
              if (column.field === columnName) {
                column = {
                  ...column,
                  filterSelected: filter,
                  filterValue: value,
                };
              }
              return column;
            }),
          }),
        },
        { ...state.models },
      ),
    }),
  ),
  on(
    tableActions.actionTableOrdering,
    (state, { contentType, orderParam }) => ({
      ...state,
      models: modelAdapter.mapOne(
        {
          id: contentType,
          map: () => ({
            orderParam,
          }),
        },
        { ...state.models },
      ),
    }),
  ),
  on(tableActions.actionTableGetSuccess, (state, { contentType }) => ({
    ...state,
    models: modelAdapter.mapOne(
      {
        id: contentType,
        map: () => ({
          callState: LoadingState.LOADED,
        }),
      },
      { ...state.models },
    ),
  })),
  on(
    objectActions.actionObjectGetSuccess,
    (state, { contentType, object }) => ({
      ...state,
      models: modelAdapter.mapOne(
        {
          id: contentType,
          map: (entity) => ({
            rows: rowAdapter.upsertOne(object, { ...entity.rows }),
          }),
        },
        { ...state.models },
      ),
    }),
  ),
  on(
    objectActions.actionObjectDeleteSucces,
    objectActions.actionObjectInlineDeleteSuccess,
    (state, { contentType, objectId }) => ({
      ...state,
      models: modelAdapter.mapOne(
        {
          id: contentType,
          map: (entity) => ({
            object_count: entity.object_count - 1,
            rows: rowAdapter.removeOne(objectId, { ...entity.rows }),
          }),
        },
        { ...state.models },
      ),
    }),
  ),
  on(
    objectActions.actionObjectUpdateSuccess,
    (state, { contentType, object }) => ({
      ...state,
      models: modelAdapter.mapOne(
        {
          id: contentType,
          map: (entity) => ({
            rows: rowAdapter.upsertOne(object, { ...entity.rows }),
          }),
        },
        { ...state.models },
      ),
    }),
  ),
  on(
    objectActions.actionObjectAddSuccess,
    objectActions.actionObjectInlineAddSuccess,
    objectActions.actionChoiceObjectAddSuccess,
    (state, { contentType, object }) => ({
      ...state,
      models: modelAdapter.mapOne(
        {
          id: contentType,
          map: (entity) => ({
            object_count: entity.object_count + 1,
            rows: rowAdapter.addOne(object, { ...entity.rows }),
          }),
        },
        { ...state.models },
      ),
    }),
  ),
  on(panelActions.actionOpenReportSuccess, (state, { payload, modelId }) => ({
    ...state,
    models: modelAdapter.mapOne(
      {
        id: modelId,
        map: (entity) => ({
          reports: entity.reports.map((report) => {
            if (report.name === payload.metadata.name) {
              report = {
                ...report,
                columns: payload.columns,
                data: payload.data,
                metadata: payload.metadata,
              };
            }

            return report;
          }),
        }),
      },
      { ...state.models },
    ),
  })),
  on(
    apiActions.actionGenericFailed,
    (state, { error }): ApiState => ({
      ...state,
      callState: { errorMsg: error },
    }),
  ),
);

/** reducer for instance settings to send to the coreState */
export const apiReducer = (
  state: ApiState | undefined,
  action: Action,
): ApiState => reducer(state, action);
