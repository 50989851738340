import * as fromRouter from '@ngrx/router-store';
import {
  ActionReducerMap,
  createFeatureSelector,
  MetaReducer,
} from '@ngrx/store';

import {
  componentsReducer,
  ComponentsState,
} from '@modules/store/component.reducer';

import { environment } from '../../environments/environment';
import { apiReducer, ApiState } from './api/api.reducer';
import { sessionReducer, SessionState } from './auth/store/session.reducer';
import { debug } from './meta-reducers/debug.reducer';
import { initStateFromLocalStorage } from './meta-reducers/init-state-from-local-storage.reducer';

/** Global app state */
export interface AppState {
  api: ApiState;
  session: SessionState;
  components: ComponentsState;
  router: fromRouter.RouterReducerState<fromRouter.SerializedRouterStateSnapshot>;
}

export const reducers: ActionReducerMap<AppState> = {
  api: apiReducer,
  components: componentsReducer,
  router: fromRouter.routerReducer,
  session: sessionReducer,
};

export const metaReducers: MetaReducer<AppState>[] = [
  initStateFromLocalStorage,
];

if (!environment.production) {
  metaReducers.unshift(debug);
}

export const selectApiState = createFeatureSelector<ApiState>('api');

export const selectComponentsState =
  createFeatureSelector<ComponentsState>('components');

export const selectSessionState =
  createFeatureSelector<SessionState>('session');

export const selectRouterState =
  createFeatureSelector<
    fromRouter.RouterReducerState<fromRouter.SerializedRouterStateSnapshot>
  >('router');
