import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * dropdown component: display a popover
 *
 * @example
 * <gogis-dropdown></gogis-dropdown>
 */
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'gogis-dropdown',
  standalone: false,
  template: '<ng-content />',
})
export class DropdownComponent implements OnInit {
  /**
   * boolean. Indicates if popover should be closed when user click inside of it.
   * Default is true.
   */
  @Input() closeOnClickInside = true;

  /**
   * Returns whether or not the popover is currently being open
   */
  isOpen = false;

  /** directive have been clicked. */
  toggleClick$ = new EventEmitter<boolean>();

  /** event about opening change. */
  isOpenChange$ = new EventEmitter<boolean>();

  /** subject to unsubscribe on destroy. */
  private destroyRef = inject(DestroyRef);

  /** @ignore */
  ngOnInit(): void {
    this.toggleClick$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: boolean) => this.toggle(value));

    this.isOpenChange$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: boolean) => (this.isOpen = value));
  }

  /**
   * toggle the statement of the popover.
   *
   * @param value true if needed to be shown or false to hide it.
   */
  toggle(value?: boolean): void {
    if (this.isOpen || value === false) {
      return this.hide();
    }

    return this.show();
  }

  /**
   * Opens an element’s popover. This is considered a “manual” triggering of
   * the popover.
   */
  show(): void {
    if (this.isOpen) {
      return;
    }

    this.isOpen = true;
    this.isOpenChange$.emit(true);
  }

  /**
   * Closes an element’s popover. This is considered a “manual” triggering of
   * the popover.
   */
  hide(): void {
    if (!this.isOpen) {
      return;
    }

    this.isOpen = false;
    this.isOpenChange$.emit(false);
  }
}
