import { createReducer, on } from '@ngrx/store';

import { CompositionsResponse } from '@core/api/models/response-composition';
import { CallState, LoadingState } from '@core/meta-reducers/call-state';

import {
  actionAddComposition,
  actionCloseCompositionDetail,
  actionCloseCompositionEditing,
  actionDeleteComposition,
  actionEditComposition,
  actionGetCompositions,
  actionGetCompositionsSuccess,
  actionOpenCompositionDetail,
  actionSaveCompositionSuccess,
} from './composition-list.actions';

export const compositionListFeatureKey = 'composition-list';

export interface CompositionListState {
  callState: CallState;
  compositions: CompositionsResponse[];
  detailsOpen?: string;
  editing?: string;
}

const initialState: CompositionListState = {
  callState: LoadingState.INIT,
  compositions: null,
};

export const compositionReducer = createReducer(
  initialState,
  on(
    actionGetCompositions,
    (state): CompositionListState => ({
      ...state,
      callState: LoadingState.LOADING,
    }),
  ),
  on(
    actionOpenCompositionDetail,
    (state, { compositionName }): CompositionListState => ({
      ...state,
      detailsOpen: compositionName,
      editing: null,
    }),
  ),
  on(
    actionCloseCompositionDetail,
    (state): CompositionListState => ({
      ...state,
      detailsOpen: null,
    }),
  ),
  on(
    actionEditComposition,
    (state, { compositionName }): CompositionListState => ({
      ...state,
      detailsOpen: null,
      editing: compositionName,
    }),
  ),
  on(
    actionCloseCompositionEditing,
    (state): CompositionListState => ({
      ...state,
      editing: null,
    }),
  ),
  on(
    actionAddComposition,
    (state): CompositionListState => ({
      ...state,
      detailsOpen: null,
      editing: 'new',
    }),
  ),
  on(
    actionGetCompositionsSuccess,
    (state, { payload }): CompositionListState => ({
      ...state,
      callState: LoadingState.LOADED,
      compositions: payload,
    }),
  ),
  on(actionSaveCompositionSuccess, (state, { payload }) => {
    const index = state.compositions.indexOf(
      state.compositions.find(({ id }) => id === payload.id),
    );
    let compositions;

    if (index !== -1) {
      compositions = [
        ...state.compositions.slice(0, index),
        { ...payload },
        ...state.compositions.slice(index + 1),
      ];
    } else {
      compositions = [...state.compositions, payload];
    }
    return {
      ...state,
      callState: LoadingState.LOADED,
      compositions,
      editing: null,
    };
  }),

  on(actionDeleteComposition, (state, { compositionId }) => {
    const index = state.compositions.indexOf(
      state.compositions.find(({ id }) => id === compositionId),
    );
    const compositions = [...state.compositions];
    compositions.splice(index, 1);

    return {
      ...state,
      callState: LoadingState.LOADED,
      compositions,
      editing: null,
    };
  }),
);
