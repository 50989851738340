import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';

import { actionForgotPassword } from '..';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'gogis-password-forgot',
  standalone: false,
  templateUrl: './password-forgot.component.html',
})
export class PasswordForgotComponent {
  username = new UntypedFormControl(null, [Validators.required]);

  constructor(
    private store: Store,
    private toastr: ToastrService,
  ) {}

  sendPasswordRecoveryLink(): void {
    if (this.username.valid) {
      this.store.dispatch(
        actionForgotPassword({ username: this.username.value }),
      );
    } else {
      this.toastr.error('Please enter your username');
    }
  }
}
