import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
} from 'rxjs';

import { actionAuthLogin } from '@core/auth';
import { LoginModalComponent } from '@core/auth/login-modal/login-modal.component';
import { aboutURL, ENTRYPOINT } from '@core/http/endpoints';
import { AboutResponse } from '@modules/about/response-about';
import {
  actionDeleteComposition,
  actionDeleteCompositionSuccess,
  actionGetCompositions,
  actionGetCompositionsSuccess,
  actionSaveComposition,
  actionSaveCompositionSuccess,
  actionSelectComposition,
} from '@modules/composition-list/store/composition-list.actions';
import * as mapPanelActions from '@modules/map-panel/store/map-panel.actions';

import * as apiActions from './api.actions';
import {
  selectAPIMapComposition,
  selectSelectedModelAndObject,
} from './api.selectors';
import { ReportData } from './models/response-report';
import { CompositionService } from './services/composition.service';
import { InstanceService } from './services/settings.service';

/** tables effects to import in EffectsModule.forRoot() */
@Injectable()
export class ApiEffects {
  /** Effects when dispatch actionInstanceSettingsGet */
  instanceSettingsRetrieve = createEffect(() =>
    this.actions$.pipe(
      ofType(
        apiActions.actionLoadInitialSettings,
        apiActions.actionAutorizationModalClose,
      ),
      concatLatestFrom(() => this.store.select(selectAPIMapComposition)),
      switchMap(([action, compositionName]) =>
        this.instanceService.getInstanceSettings(compositionName).pipe(
          map((resp) =>
            apiActions.actionInstanceSettingsGetSuccess({ payload: resp }),
          ),
          catchError((error: unknown) => {
            if (
              error instanceof Array &&
              (error as unknown as string).includes(
                'Authentication credentials were not provided',
              )
            ) {
              return of(apiActions.actionUnautorizedComposition());
            }
            return of(
              apiActions.actionGenericFailed({
                actionType: action.type,
                error: error.toString(),
              }),
            );
          }),
        ),
      ),
    ),
  );

  loadNewComposition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionSelectComposition),
      tap((action) => {
        this.router.navigate([`/map`], {
          queryParams: {
            composition: action.compositionName,
            object: null,
            report: null,
            table: null,
          },
          queryParamsHandling: 'merge',
        });
      }),
      switchMap((action) =>
        this.instanceService.getInstanceSettings(action.compositionName).pipe(
          map((resp) =>
            apiActions.actionInstanceSettingsGetSuccess({ payload: resp }),
          ),
          catchError((error: unknown) => {
            if (
              (error as string).includes(
                'Authentication credentials were not provided',
              )
            ) {
              return of(apiActions.actionUnautorizedComposition());
            }
            return of(
              apiActions.actionGenericFailed({
                actionType: action.type,
                error: error.toString(),
              }),
            );
          }),
        ),
      ),
    ),
  );

  authenticationRequired$ = createEffect(() =>
    this.actions$.pipe(
      ofType(apiActions.actionUnautorizedComposition),
      exhaustMap(() => {
        const dialogRef = this.dialog.open(LoginModalComponent, {
          maxWidth: 300,
        });
        return dialogRef.afterClosed();
      }),
      map((result) => {
        if (!result) {
          this.router.navigate([`/map`]);
          return apiActions.actionAutorizationModalClose();
        }
        return actionAuthLogin({ credentials: result });
      }),
    ),
  );

  getReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mapPanelActions.actionOpenReport),
      concatLatestFrom(() => this.store.select(selectSelectedModelAndObject)),
      tap(() => {
        this.router.navigate(['/map'], {
          queryParams: { report: '' },
          queryParamsHandling: 'merge',
        });
      }),
      switchMap(([action, modelObject]) =>
        this.http.get<ReportData>(ENTRYPOINT + action.url.substring(1)).pipe(
          map((response) =>
            mapPanelActions.actionOpenReportSuccess({
              modelId: modelObject.selectedModel.django_content_type,
              payload: response,
            }),
          ),
          catchError((error: unknown) =>
            of(
              apiActions.actionGenericFailed({
                actionType: action.type,
                error: error.toString(),
              }),
            ),
          ),
        ),
      ),
    ),
  );

  aboutRetrieve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(apiActions.actionAboutGet),
      switchMap((action) =>
        this.http.get<AboutResponse>(aboutURL).pipe(
          map((response) =>
            apiActions.actionAboutGetSuccess({ payload: response }),
          ),
          catchError((error: unknown) =>
            of(
              apiActions.actionGenericFailed({
                actionType: action.type,
                error: error.toString(),
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getMapCompositions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionGetCompositions),
      switchMap((action) =>
        this.compositionsService.getMapcompositions().pipe(
          map((resp) =>
            actionGetCompositionsSuccess({ payload: resp.results }),
          ),
          catchError((error: unknown) =>
            of(
              apiActions.actionGenericFailed({
                actionType: action.type,
                error: error.toString(),
              }),
            ),
          ),
        ),
      ),
    ),
  );

  saveMapCompositions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionSaveComposition),
      switchMap((action) =>
        this.compositionsService
          .saveMapcompositions(action.data, action.compositionId)
          .pipe(
            map((resp) => actionSaveCompositionSuccess({ payload: resp })),
            catchError((error: unknown) =>
              of(
                apiActions.actionGenericFailed({
                  actionType: action.type,
                  error: error.toString(),
                }),
              ),
            ),
          ),
      ),
    ),
  );

  deleteMapCompositions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionDeleteComposition),
      mergeMap((action) =>
        this.compositionsService
          .deleteMapcompositions(action.compositionId)
          .pipe(
            map((resp) => actionDeleteCompositionSuccess({ payload: resp })),
            catchError((error: unknown) =>
              of(
                apiActions.actionGenericFailed({
                  actionType: action.type,
                  error: error.toString(),
                }),
              ),
            ),
          ),
      ),
    ),
  );

  /** @ignore */
  constructor(
    private http: HttpClient,
    private router: Router,
    private dialog: MatDialog,
    private store: Store,
    private actions$: Actions,
    private instanceService: InstanceService,
    private compositionsService: CompositionService,
  ) {}
}
