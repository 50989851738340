import { Action, combineReducers } from '@ngrx/store';

import * as fromCompostion from '@modules/composition-list/store/composition-list.reducer';
import * as fromIssue from '@modules/issue/store/issue-list.reducer';
import * as fromMap from '@modules/map/store/map.reducer';
import * as fromMapPanel from '@modules/map-panel/store/map-panel.reducer';
import * as fromMeasurement from '@modules/measurement/store/measurement.reducer';
import * as fromSidebar from '@modules/sidebar/store/sidebar.reducer';
import * as fromFilter from '@modules/spatial-search/spatial-filter/store/spatial-filter.reducer';
import * as fromSearch from '@modules/spatial-search/store/spatial-search.reducer';

export interface ComponentsState {
  [fromSidebar.sidebarFeatureKey]: fromSidebar.SidebarState;
  [fromMap.mapFeatureKey]: fromMap.MapState;
  [fromSearch.searchFeatureKey]: fromSearch.SearchState;
  [fromFilter.filterFeatureKey]: fromFilter.SearchFilterState;
  [fromMeasurement.measurmentFeatureKey]: fromMeasurement.MeasurementState;
  [fromMapPanel.mapPanelFeatureKey]: fromMapPanel.MapPanelState;
  [fromCompostion.compositionListFeatureKey]: fromCompostion.CompositionListState;
  [fromIssue.issueListFeatureKey]: fromIssue.IssueListState;
}

export const componentsReducer = (
  state: ComponentsState | undefined,
  action: Action,
): any =>
  combineReducers({
    [fromSearch.searchFeatureKey]: fromSearch.spatialSearchReducer,
    [fromFilter.filterFeatureKey]: fromFilter.searchFilterReducer,
    [fromSidebar.sidebarFeatureKey]: fromSidebar.sidebarReducer,
    [fromMeasurement.measurmentFeatureKey]: fromMeasurement.measurementReducer,
    [fromMap.mapFeatureKey]: fromMap.mapReducer,
    [fromMapPanel.mapPanelFeatureKey]: fromMapPanel.mapPanelReducer,
    [fromIssue.issueListFeatureKey]: fromIssue.issueReducer,
    [fromCompostion.compositionListFeatureKey]:
      fromCompostion.compositionReducer,
  })(state, action);
