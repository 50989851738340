/* eslint-disable rxjs-x/no-subscribe-in-pipe */
/* eslint-disable @ngrx/no-multiple-actions-in-effects */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, of, switchMap, tap } from 'rxjs';

import {
  actionAboutGet,
  actionLoadInitialSettings,
} from '@core/api/api.actions';
import { deleteCookieURL } from '@core/http/endpoints';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { MapUtilsService } from '@modules/map/map-utils.service';
import { actionClearResults } from '@modules/spatial-search/store/spatial-search.actions';

import { AuthService } from '../auth.service';
import * as authActions from './auth.actions';
import { selectTokenId } from './session.selectors';

@Injectable()
export class AuthEffects {
  login = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.actionAuthLogin),
      switchMap((action) =>
        this.authService.login(action.credentials).pipe(
          map((response) => authActions.actionAuthLoginSuccess({ response })),
          tap(({ response }) => {
            this.toastrService.success(
              this.translateService.instant('PROFILE.SIGN_IN_SUCCESS'),
            );

            this.localStorageService.setItem('SESSION', {
              tokenid: response.token,
            });
          }),
          catchError((error: unknown) =>
            of(authActions.actionAuthLoginFailure({ error: error.toString() })),
          ),
        ),
      ),
    ),
  );

  loginSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.actionAuthLoginSuccess),
      map(({ response }) => {
        this.authService.startTimerUntillInactivity(response.token);
        this.authService.refreshToken(response.token);
      }),
      switchMap(() => [
        authActions.actionGetUser(),
        actionLoadInitialSettings(),
        actionAboutGet(),
      ]),
    ),
  );

  refreshToken = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.actionRefreshToken),
      concatLatestFrom(() => this.store.select(selectTokenId)),
      switchMap(([, tokenId]) =>
        this.authService.refresh(tokenId).pipe(
          map((token) =>
            authActions.actionRefreshTokenSuccess({ response: token }),
          ),
          catchError(() => of(authActions.actionAuthLogout())),
        ),
      ),
    ),
  );

  refreshTokenSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.actionRefreshTokenSuccess),
        tap(({ response }) => {
          this.authService.refreshToken(response.token);
          this.localStorageService.setItem('SESSION', {
            tokenid: response.token,
          });
        }),
      ),
    { dispatch: false },
  );

  checkLogin = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.actionCheckInitialLogin),
      concatLatestFrom(() => this.store.select(selectTokenId)),
      switchMap(([, tokenId]) => {
        if (!tokenId) {
          return of(authActions.actionCheckLoginFailure({ error: 'no token' }));
        } else {
          return this.authService.refresh(tokenId).pipe(
            map((token) =>
              authActions.actionCheckLoginSuccess({ response: token }),
            ),
            catchError((error: unknown) =>
              of(
                authActions.actionCheckLoginFailure({
                  error: error.toString(),
                }),
              ),
            ),
          );
        }
      }),
    ),
  );

  checkLoginSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.actionCheckLoginSuccess),
      tap(({ response }) => {
        this.authService.startTimerUntillInactivity(response.token);
      }),
      switchMap(() => [
        authActions.actionRefreshToken(),
        authActions.actionGetUser(),
      ]),
    ),
  );

  logout = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.actionAuthLogout),
      tap(() => {
        this.authService.clearRefreshTimer();
        this.router.navigate(['']);
        this.localStorageService.removeItem('SESSION');
        this.http.post(deleteCookieURL, {}).subscribe();
        this.toastrService.success(
          this.translateService.instant('PROFILE.SIGN_OUT_SUCCESS'),
        );
      }),
      switchMap(() => [
        actionClearResults(),
        actionLoadInitialSettings(),
        actionAboutGet(),
      ]),
    ),
  );

  tokenExpire = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.actionTokenExpire),
        tap(() => {
          this.localStorageService.removeItem('SESSION');
        }),
      ),
    { dispatch: false },
  );

  /** Retreive current profile */
  retrieveProfile = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.actionGetUser),
      switchMap(() =>
        this.authService.getMyProfile().pipe(
          map((response) => {
            // set user preference settings
            const preference = response.preferences;
            const lang = preference.language;
            if (lang) {
              this.translateService.use(lang);
            }
            const proj = preference.projection;
            if (proj) {
              this.mapUtilsService.changeProjection('EPSG:' + proj);
            }
            if (response.preferences.save_session) {
              this.mapUtilsService.loadMapSession(preference);
            }

            if (!response.attributes.privatuma_politika) {
              this.authService.askTermsAgreement();
            }

            return authActions.actionGetUserSuccess({ response });
          }),
          catchError((error: unknown) =>
            of(authActions.actionGetUserFailure({ error: error.toString() })),
          ),
        ),
      ),
    ),
  );

  /** @ignore */
  constructor(
    private router: Router,
    private http: HttpClient,
    private store: Store,
    private actions$: Actions,
    private translateService: TranslateService,
    private toastrService: ToastrService,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    private mapUtilsService: MapUtilsService,
  ) {}
}
