/* eslint-disable no-console */
import { ActionReducer } from '@ngrx/store';

import { AppState } from '@core/core.state';

export const debug =
  (reducer: ActionReducer<AppState>): ActionReducer<AppState> =>
  (state, action) => {
    const newState = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('oldState', state);
    console.log('action', action);
    console.log('newState', newState);
    console.groupEnd();

    return newState;
  };
