import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';

/**
 * AppModule should stay virtually empty for the whole life-time of the project,
 * everything that needs to be available from start should be added to the CoreModule
 */
@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [CoreModule, AppRoutingModule],
  providers: [],
})
export class AppModule {}
