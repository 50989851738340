import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { NgSelectConfig } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { Field } from '@core/api/models';

import { UserRegistration } from '../profile';
import { ProfileService } from '../profile.service';
import { actionRegisterUser } from '../store/profile.actions';

interface UserType {
  name: string;
  value: string;
}
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NgSelectConfig],
  selector: 'gogis-registration',
  standalone: false,
  templateUrl: './registration.component.html',
})
export class RegistrationComponent {
  registrationFields: Field[] = [
    {
      label: 'PROFILE.REGISTRATION.USERNAME',
      min_length: 3,
      name: 'username',
      placeholder: 'JānisB',
      required: true,
      type: 'string',
    },
    {
      label: 'PROFILE.REGISTRATION.EMAIL',
      name: 'email',
      placeholder: 'jānis.berzins@email.lv',
      required: true,
      type: 'email',
    },
    {
      label: 'PROFILE.REGISTRATION.FIRST_NAME',
      name: 'firstName',
      placeholder: 'Jānis',
      required: true,
      type: 'string',
    },
    {
      label: 'PROFILE.REGISTRATION.LAST_NAME',
      name: 'lastName',
      placeholder: 'Bērziņš',
      required: true,
      type: 'string',
    },
    {
      label: 'PROFILE.REGISTRATION.PASSWORD',
      name: 'password',
      placeholder: '*********',
      required: true,
      type: 'password',
    },
    {
      label: 'PROFILE.REGISTRATION.CONFIRM_PASSWORD',
      name: 'confirmPassword',
      placeholder: '*********',
      required: true,
      type: 'password',
    },
    {
      choices: [
        {
          display_name: this.translate.instant(
            'PROFILE.REGISTRATION.USER_TYPE_A',
          ),
          value: 'A',
        },
        {
          display_name: this.translate.instant(
            'PROFILE.REGISTRATION.USER_TYPE_F',
          ),
          value: 'F',
        },
        {
          display_name: this.translate.instant(
            'PROFILE.REGISTRATION.USER_TYPE_J',
          ),
          value: 'J',
        },
      ],
      label: 'PROFILE.REGISTRATION.USER_TYPE',
      name: 'userTypeSelection',
      required: true,
      type: 'choice',
    },
    {
      label: 'PROFILE.REGISTRATION.TERMS_AND_CONDITIONS',
      name: 'termsAndConditions',
      required: true,
      type: 'boolean',
    },
  ];

  registrationForm = new UntypedFormGroup({
    confirmPassword: new UntypedFormControl(null, [
      Validators.required,
      this.profileService.confirmPasswordValidation,
    ]),
    email: new UntypedFormControl(null, [
      Validators.required,
      Validators.email,
    ]),
    firstName: new UntypedFormControl(null, [Validators.required]),
    lastName: new UntypedFormControl(null, [Validators.required]),
    password: new UntypedFormControl(null, [
      Validators.required,
      Validators.minLength(9),
      this.profileService.checkPasswordStrength,
    ]),
    termsAndConditions: new UntypedFormControl(null, [Validators.required]),
    userTypeSelection: new UntypedFormControl('A', [Validators.required]),
    username: new UntypedFormControl(null, [Validators.required]),
  });

  userTypes: UserType[] = [
    {
      name: this.translate.instant('PROFILE.REGISTRATION.USER_TYPE_A'),
      value: 'A',
    },
    {
      name: this.translate.instant('PROFILE.REGISTRATION.USER_TYPE_F'),
      value: 'F',
    },
    {
      name: this.translate.instant('PROFILE.REGISTRATION.USER_TYPE_J'),
      value: 'J',
    },
  ];

  constructor(
    private store: Store,
    private toastr: ToastrService,
    private translate: TranslateService,
    private profileService: ProfileService,
  ) {}

  sendRegistration(): void {
    this.registrationForm.markAllAsTouched();
    if (this.registrationForm.valid) {
      this.store.dispatch(
        actionRegisterUser({ form: this.getFormValues(this.registrationForm) }),
      );
    } else {
      this.toastr.error('Form is not valid');
    }
  }

  getControl(controlName: string): AbstractControl {
    return this.registrationForm.get(controlName);
  }

  private getFormValues(registrationForm: UntypedFormGroup): UserRegistration {
    return {
      email: registrationForm.value.email,
      first_name: registrationForm.value.firstName,
      last_name: registrationForm.value.lastName,
      password: registrationForm.value.password,
      password_confirm: registrationForm.value.confirmPassword,
      privatuma_politika: registrationForm.value.termsAndConditions,
      tips: registrationForm.value.userTypeSelection,
      username: registrationForm.value.username,
    };
  }
}
