import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { take } from 'rxjs';

import { TableResponse } from '@core/api/models';
import { dataURL } from '@core/http/endpoints';

/**
 * Creates/edits Issue.
 */
@Component({
  selector: 'gogis-issue-add-edit-modal',
  standalone: false,
  template: `
    <button class="close" mat-button class="float-right" mat-dialog-close>
      X
    </button>
    <h2 mat-dialog-title>Assign</h2>
    <mat-dialog-content>
      <mat-divider />
      <input
        class="w-full"
        type="text"
        title="Search a user"
        placeholder="Search a user"
        (keyup)="enteredValue($event)"
      />
      <mat-list *ngIf="listOfUser">
        <div
          *ngFor="
            let user of listOfUser
              | userSearchFilter: searchText
              | slice: 0 : 5 as result;
            else: noSearchedItems
          "
        >
          <mat-list-item [mat-dialog-close]="user.id"
            ><button class="flex items-center">
              <ng-container *ngIf="user.avatar; else noAvatarTemplate">
                <gogis-secure-image
                  class="mr-2 h-8 w-8 rounded-full"
                  alt="avatar"
                  src="{{ user.avatar }}"
                />
              </ng-container>
              <ng-template #noAvatarTemplate>
                <svg-icon
                  key="user"
                  size="md"
                  class="mr-2 overflow-hidden rounded-full text-gis-grey-400"
                />
              </ng-template>
              {{ user.nosaukums }}
            </button></mat-list-item
          >
          <mat-divider />
        </div>

        <div>
          {{
            (listOfUser | userSearchFilter: searchText | slice: 0 : 5).length
          }}
          of {{ listOfUser?.length }} results
        </div>
      </mat-list>
      <gogis-button
        *ngIf="data"
        class="grid"
        label="Remove user"
        color="grey"
        [mat-dialog-close]="'wrong'"
      />
    </mat-dialog-content>
  `,
})
export class TaskAssignUserModalComponent {
  listOfUser: any[];

  searchText = '';

  constructor(
    private httpClient: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) {
    this.httpClient
      .get(
        `${dataURL}/lietotaji/profile?fields=id,avatar,nosaukums&data_format=full`,
      )
      .pipe(take(1), takeUntilDestroyed())
      .subscribe((resp: TableResponse<any>) => {
        this.listOfUser = resp.results;
      });
  }

  /** Excute on input search keyup */
  enteredValue(event: any): void {
    this.searchText = event.target.value;
  }
}
