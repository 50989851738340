import { Action, createReducer, on } from '@ngrx/store';

import * as headerActions from '@core/header/store/header.actions';
import { CallState, LoadingState } from '@core/meta-reducers/call-state';
import * as footerActions from '@modules/map/footer/store/footer.actions';
import {
  actionSaveLayersPositionFailure,
  actionSaveLayersPositionSuccess,
} from '@modules/map/store/map.actions';
import {
  ProfileActivities,
  ProfileAttributes,
  ProfilePermissions,
  ProfilePreferences,
} from '@modules/profile';
import * as profileSettingsActions from '@modules/profile/store/profile.actions';

import * as authActions from './auth.actions';

export interface SessionState {
  callState: CallState;
  /** tokenid recieve when loing or refreshLogin */
  tokenid?: string;
  user?: ProfileAttributes;
  preferences?: ProfilePreferences;
  permissions?: ProfilePermissions[];
  activities?: ProfileActivities[];
}

/** InitialState */
export const initialState: SessionState = {
  callState: LoadingState.INIT,
};

/** handle transitions from one state to the next state */
const reducer = createReducer(
  initialState,
  on(
    authActions.actionCheckInitialLogin,
    authActions.actionAuthLogin,
    authActions.actionGetUser,
    profileSettingsActions.actionUpdateUserSettings,
    (state): SessionState => ({
      ...state,
      callState: LoadingState.LOADING,
    }),
  ),
  on(
    authActions.actionGetUserSuccess,
    headerActions.actionChangeLanguageSuccess,
    profileSettingsActions.actionUpdateUserSettingsSuccess,
    profileSettingsActions.actionUpdateProfilePreferencesSuccess,
    profileSettingsActions.actionSaveMapSessionSuccess,
    footerActions.actionChangeProjectionSuccess,
    actionSaveLayersPositionSuccess,
    (state, { response }): SessionState => ({
      ...state,
      activities: response.activities,
      callState: LoadingState.LOADED,
      permissions: response.permissions,
      preferences: response.preferences,
      user: response.attributes,
    }),
  ),
  on(
    authActions.actionAuthLoginSuccess,
    authActions.actionCheckLoginSuccess,
    authActions.actionRefreshTokenSuccess,
    (state, { response }): SessionState => ({
      ...state,
      callState: LoadingState.LOADED,
      tokenid: response.token,
    }),
  ),
  on(
    authActions.actionAuthLogout,
    authActions.actionCheckLoginFailure,
    authActions.actionRefreshTokenFailure,
    authActions.actionTokenExpire,
    (state): SessionState => ({
      ...state,
      activities: null,
      callState: LoadingState.LOADED,
      permissions: null,
      preferences: null,
      tokenid: null,
      user: null,
    }),
  ),
  on(
    authActions.actionAuthLoginFailure,
    authActions.actionCheckLoginFailure,
    (state, { error }): SessionState => ({
      ...state,
      activities: null,
      callState: { errorMsg: error },
      permissions: null,
      preferences: null,
      tokenid: null,
      user: null,
    }),
  ),
  on(
    profileSettingsActions.actionUpdateUserSettingsFailure,
    authActions.actionGetUserFailure,
    actionSaveLayersPositionFailure,
    (state, { error }): SessionState => ({
      ...state,
      callState: { errorMsg: error },
    }),
  ),
);

/** reducer for authentication to send to the coreState */
export const sessionReducer = (
  state: SessionState | undefined,
  action: Action,
): SessionState => reducer(state, action);
