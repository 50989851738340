import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'gogis-red-box',
  standalone: false,
  template: `
    <div class="h-8 w-8 animate-spin bg-gis-red-600" [ngClass]="class"></div>
  `,
})
export class RedBoxComponent {
  @Input() class: string;
}
