import { Injectable } from '@angular/core';

import proj4 from 'proj4';

import { Collection, Feature, Map, View } from 'ol';
import WKT from 'ol/format/WKT';
import { Geometry } from 'ol/geom';
import { Interaction } from 'ol/interaction';
import VectorLayer from 'ol/layer/Vector';
import { register } from 'ol/proj/proj4';
import VectorSource from 'ol/source/Vector';
import Bar from 'ol-ext/control/Bar';

import { MapStyleService } from '@modules/map/map-style.service';

@Injectable()
export class SimplemapService {
  map: Map;

  controlBar: Bar;

  constructor(private mapStyleService: MapStyleService) {}

  /**
   * defines and registers the projection
   *  so it could be used for this map
   */
  setProjectionDefs(projection: string, proj4text: string): void {
    // FIX ME should register only once not for every map
    proj4.defs(projection, proj4text);
    register(proj4);
  }

  /**
   * Create a map
   *
   * @param  mapId      DOM id that this map is attached to
   * @param  mapView    The view created in simplemap.component
   */
  createMap(
    mapId: string,
    mapView: View,
    mapInteractions: Collection<Interaction>,
  ): Map {
    this.map = new Map({
      interactions: mapInteractions,
      target: mapId,
      view: mapView,
    });
    return this.map;
  }

  /**
   * creates a vectorLayer with all the geometry
   *  from the given geometry string from input
   */
  createVectorLayer(
    geometry: string,
  ): VectorLayer<VectorSource<Feature<Geometry>>> {
    const style = this.mapStyleService.generateStyle('blue');
    const format = new WKT();
    return new VectorLayer({
      source: new VectorSource({
        features: [
          format.readFeature(geometry, {
            dataProjection: 'EPSG:3059',
          }),
        ],
      }),
      style,
    });
  }

  /** fit map to the geometry of the first feature in vectorLayer */
  zoomToGeometry(
    geometryLayer: VectorLayer<VectorSource<Feature<Geometry>>>,
  ): void {
    const geometry = geometryLayer.getSource().getFeatures()[0].getGeometry();
    this.map.getView().fit(geometry.getExtent(), { padding: [10, 10, 10, 10] });
  }
}
