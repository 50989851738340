import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';

import { ResetPassword } from '..';
import { ProfileService } from '../profile.service';
import { actionResetPassword } from '../store/profile.actions';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'gogis-password-reset',
  standalone: false,
  templateUrl: './password-reset.component.html',
})
export class PasswordResetComponent {
  passwordReset = new UntypedFormGroup({
    confirmPassword: new UntypedFormControl(null, [
      Validators.required,
      this.profileService.confirmPasswordValidation,
    ]),
    password: new UntypedFormControl(null, [
      Validators.required,
      this.profileService.checkPasswordStrength,
    ]),
  });

  constructor(
    private store: Store,
    @Inject(MAT_DIALOG_DATA) private token: string,
    private toastr: ToastrService,
    private profileService: ProfileService,
  ) {}

  resetPassword(): void {
    if (this.passwordReset.valid) {
      const form: ResetPassword = {
        confirm: this.getControl('confirmPassword').value,
        new: this.getControl('password').value,
        token: this.token,
      };
      this.store.dispatch(actionResetPassword({ form }));
    } else {
      this.toastr.error('Form is invalid');
    }
  }

  getControl(controlName: string): AbstractControl {
    return this.passwordReset.get(controlName);
  }
}
