import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';

import {
  AppsResponse,
  AttachmentResponse,
  IssueResponse,
  ModelNormalize,
} from '@core/api/models';
import { selectApiState, selectRouterState } from '@core/core.state';
import { LoadingState } from '@core/meta-reducers/call-state';
import { GeometryField } from '@modules/map/feature-edit/feature-edit.component';
import {
  selectIsEditing,
  selectMapPanel,
} from '@modules/store/component.selectors';

import { modelAdapter, rowAdapter } from './api.reducer';

export const selectAPISettings = createSelector(
  selectApiState,
  (state) => state.settings,
);

export const selectAPIMapSettings = createSelector(
  selectApiState,
  (state) => state.mapSettings,
);

export const selectAPIMapComposition = createSelector(
  selectApiState,
  (state) => state?.settings?.composition_name || state.composition_name,
);

export const selectAvailableProjections = createSelector(
  selectAPIMapSettings,
  (settings) => settings?.projections,
);

export const selectAPIAbouData = createSelector(
  selectApiState,
  (state) => state.about,
);

export const selectWeblayers = createSelector(
  selectApiState,
  (state) => state.mapSettings.groups,
);

export const selectModels = createSelector(
  selectApiState,
  (state) => state.models,
);

export const selectAttachmentFieldsets = createSelector(
  selectApiState,
  (state) => state.attachmentFieldsets,
);

export const selectIssueFieldsets = createSelector(
  selectApiState,
  (state) => state.issueFieldsets,
);

const { selectEntities: selectEntitiesModels, selectIds } =
  modelAdapter.getSelectors();

export const selectModelsEntities = createSelector(
  selectModels,
  selectEntitiesModels,
);

export const selectModelIds = createSelector(selectModels, selectIds);

export const selectModelsIsLoaded = createSelector(
  selectModels,
  (models) => models.callState === LoadingState.LOADED,
);

export const selectAPIModelsByApps = createSelector(
  selectApiState,
  selectModelsEntities,
  (state, entities) => {
    const appsByGroupId = Object.keys(entities).reduce(
      (dictionnary, key) => {
        const groupedResponse = dictionnary[entities[key].app_name];
        if (groupedResponse) {
          groupedResponse.models.push({ ...entities[key] });
        } else {
          const app = state.apps.find(
            ({ name }) => entities[key].app_name === name,
          );
          if (app) {
            dictionnary[entities[key].app_name] = {
              ...app,
              models: [{ ...entities[key] }],
            };
          }
        }
        return dictionnary;
      },
      {} as Record<string, AppsResponse>,
    );

    // Sort the models by alphabetic order
    Object.values(appsByGroupId).map((app) => {
      app.models.sort((a, b) => {
        const nameA = a.verbose_name.toLowerCase();
        const nameB = b.verbose_name.toLowerCase();
        if (nameA === nameB) {
          return 0;
        }
        return nameA < nameB ? -1 : 1;
      });
    });

    // Sort the applications by alphabetic order
    return Object.values(appsByGroupId).sort((a, b) => {
      const nameA = a.verbose_name.toLowerCase();
      const nameB = b.verbose_name.toLowerCase();
      if (nameA === nameB) {
        return 0;
      }
      return nameA < nameB ? -1 : 1;
    });
  },
);

export const selectSelectedModel = createSelector(
  selectModelsEntities,
  selectRouterState,
  (entities, router) => entities[router?.state.root.queryParams.table],
);

export const selectSelectedModelRows = createSelector(
  selectModelsEntities,
  selectRouterState,
  (entities, router) => entities[router?.state.root.queryParams.table]?.rows,
);

export const selectModelOptionsById = (id: number) =>
  createSelector(
    selectModelsEntities,
    (entities: Dictionary<ModelNormalize>) => entities[id]?.fieldsets,
  );

export const selectAPIModelById = (id: number) =>
  createSelector(
    selectModelsEntities,
    (entities: Dictionary<ModelNormalize>) => entities[id],
  );

export const selectAPIModelByModelName = (appName: string, modelName: string) =>
  createSelector(
    selectModelsEntities,
    (entities: Dictionary<ModelNormalize>) => {
      for (const key in entities) {
        if (Object.prototype.hasOwnProperty.call(entities, key)) {
          const model = entities[key];
          if (
            model.app_name === appName &&
            model.name === modelName.toLocaleLowerCase()
          ) {
            return model;
          }
        }
      }
      return null;
    },
  );

export const selectAPIAppByName = (appName: string) =>
  createSelector(selectApiState, (state) =>
    state.apps.find(({ name }) => name === appName),
  );

export const selectGeometryFields = createSelector(
  selectSelectedModel,
  (model) => {
    const geometryFields: GeometryField[] = [];
    model?.fieldsets?.forEach((fieldset) =>
      fieldset.fields.forEach((f) => {
        if (f.type === 'geometry') {
          geometryFields.push(f);
        }
      }),
    );
    return geometryFields;
  },
);

export const selectObjectEditAndModelGeometry = createSelector(
  selectSelectedModel,
  selectIsEditing,
  (model, isEditing) => (model?.geometry && isEditing ? true : false),
);

export const selectSelectedObject = createSelector(
  selectSelectedModelRows,
  selectRouterState,
  (selectedRows, router) =>
    selectedRows?.entities[router?.state.root.queryParams.object],
);

export const selectSelectedModelAndObject = createSelector(
  selectSelectedModel,
  selectSelectedObject,
  (selectedModel, selectedObject) => ({ selectedModel, selectedObject }),
);

export const selectTempAttachments = createSelector(
  selectMapPanel,
  (mapPanel) => mapPanel.tempAttachments,
);

export const selectTempIssue = createSelector(
  selectMapPanel,
  (mapPanel) => mapPanel.tempIssues,
);

export const selectObjectAttachments = createSelector(
  selectSelectedObject,
  selectTempAttachments,
  (selectedObject, tempAttachments) => {
    let attachments: AttachmentResponse[] = [];
    if (selectedObject?.attachments) {
      attachments = selectedObject?.attachments as AttachmentResponse[];
    } else {
      tempAttachments.forEach((temp) => {
        if (temp) {
          const attObj: AttachmentResponse = {
            callState: LoadingState.INIT,
            id: null,
          };
          temp.forEach((value, key) => {
            attObj[key] = value;
          });
          attachments.push(attObj);
        }
      });
    }
    return attachments || [];
  },
);
export const selectObjectIssues = createSelector(
  selectSelectedObject,
  selectTempIssue,
  (selectedObject, tempIssues) => {
    let issues: IssueResponse[] = [];
    if (selectedObject?.issueables) {
      issues = selectedObject?.issueables as IssueResponse[];
    } else {
      tempIssues.forEach((temp) => {
        if (temp) {
          issues.push(temp.issue);
        }
      });
    }
    return issues;
  },
);

// FIXME: get an error message if no selectSelectedModelRows...
export const {
  selectIds: selectRowIds,
  selectEntities: selectRowsEntities,
  selectAll: selectAllRows,
  selectTotal: selectTotalRows,
} = rowAdapter.getSelectors(selectSelectedModelRows);
