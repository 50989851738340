<button
  class="relative flex min-h-[2em] w-full items-center border border-transparent bg-gis-green-200 px-3 text-left shadow hover:bg-gis-green-300 focus:border-gis-green-600 focus:outline-none print:mt-8"
  data-cy="accordion-button"
  [ngClass]="{ 'bg-gis-green-300': isExpended }"
  (click)="toggle()"
>
  <svg
    class="mr-3 h-6 w-6 flex-none transform transition duration-150 ease-in-out print:hidden"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    [ngClass]="{ '-rotate-90': !isExpended }"
  >
    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
  </svg>
  <ng-content select="[accordion-name]" />
  <div class="my-1 ml-auto mr-2 flex items-center">
    <ng-content select="[accordion-name-addon]" />
  </div>
</button>
<div [ngClass]="{ hidden: !isExpended }">
  <ng-content select="[accordion-content]" />
</div>
